<template>
  <div class="system-user-container">
    <base-table
      ref="tableRef"
      :config="tableConfig"
      :ref-menu-id="refMenuId"
      table-height="auto"
      @header-action="handleHeaderAction"
      @table-action="handleTableAction"
    >
      <!-- 产品编码 -->
      <template #productCode="{ scope }">
        <base-code :info="scope?.product_info || {}" />
      </template>
      <template #product_price_policy_list="{ scope }">
        <div>
          <div
            v-for="(item, index) in scope.product_price_policy_list"
            :key="index"
          >
            {{ t('数量大于等于') }}
            <span class="b cd">{{ item.over_num }}</span>
            {{ t('价格为') }}
            <span class="b cd">
              {{ CURRENCY_UNIT[scope.currency] }}{{ item.price }}
            </span>
          </div>
        </div>
      </template>
      <!-- 分页之后 -->
      <template #table-pagination-after v-if="params?.from === 'product'">
        <!-- 产品供应商分布 -->
        <ProductSupplierStatistics :info="info" :params="params" />
      </template>
    </base-table>
    <!-- 供应商 -->
    <supplier-drawer
      v-if="showSupplierDrawer"
      ref="supplierDrawer"
      module="product"
      :ref-id="info.id"
      @add-success="handleRefresh"
      @close="handlClose"
    />
    <!-- 价格策略 -->
    <el-dialog
      v-model="showDialog"
      append-to-body
      center
      destroy-on-close
      :title="dialogTitle"
      width="60%"
    >
      <ProductPriceStrategyList
        v-if="showDialog"
        :info="openData.product_info"
        :params="openData"
        :supplier-id="openData.supplier_id"
      />
    </el-dialog>
  </div>
</template>

<script>
  import {
    computed,
    defineComponent,
    nextTick,
    reactive,
    ref,
    toRefs,
    watchEffect,
  } from 'vue'
  import SupplierDrawer from './supplierDrawer.vue'
  import {
    YES_NO,
    PRODUCT_SOURCE_COLOR,
    CURRENCY_UNIT,
  } from '@/config/variable.config'
  import ProductPriceStrategyList from '@/views/product/components/productPriceStrategyList.vue' // 价格策略
  import ProductSupplierStatistics from './productSupplierStatistics.vue'
  import { useStore } from 'vuex'
  import { handleSupportCurrency } from '@/utils/business'
  import { customerSupplierProductSearchConfig } from '@/utils/headerSearchConfig'
  import { t } from '@/utils/i18n'

  import { objToArr } from '@/utils/index'
  export default defineComponent({
    name: 'ProductSupplier',
    components: {
      SupplierDrawer,
      ProductPriceStrategyList,
      ProductSupplierStatistics,
    },
    props: {
      info: { type: Object, default: () => {} },
      params: { type: Object, default: () => {} },
      refMenuId: { type: [Number, String], default: '' }, // 关联的菜单ID
    },
    setup(props) {
      const { supportCurrency } = handleSupportCurrency()
      console.log('supportCurrency', supportCurrency)
      const moduleObj = {
        supplier: {
          module: 'supplier-product',
        },
        product: {
          module: 'supplier-product',
        },
        customerList: {
          module: 'customer-product',
        },
      }
      const MODULE = moduleObj[props.params?.from].module || 'supplier-product' // 模块

      console.log('refMenuId :>> ', props.refMenuId)
      const store = useStore()
      // 表格数据
      const tableColumns = [
        {
          hide:
            props.params?.from === 'supplier' ||
            props.params?.from === 'customerList',
          label: '供应商',
          fixed: 'left',
          prop: 'supplier_info.title',
          minWidth: 180,
          class: 'cp cb tdu',
          align: 'left',
          publicDrawerConfig: {
            openName: 'supplierDetail',
            api: {
              // searchKey: 'id',
              valueKey: 'supplier_id',
            },
          },
        },
        {
          hide: props?.params?.from === 'product',
          label: '产品图片',
          fixed: 'left',
          prop: 'product_info.image',
          width: 100,
          isImg: true,
        },
        {
          hide: props?.params?.from === 'product',
          label: '产品',
          minWidth: 180,
          fixed: 'left',
          prop: 'product_info.title',
          class: props?.params?.from === 'product' ? '' : 'cp cb tdu',
          align: 'left',
          publicDrawerConfig:
            props?.params?.from === 'product'
              ? null
              : {
                  openName: 'productDetail',
                  api: {
                    searchKey: 'id',
                    valueKey: 'product_id',
                  },
                },
        },
        {
          hide: props?.params?.from === 'product',
          label: '产品编码',
          prop: 'productCode',
          width: 150,
        },
        { label: '备注', prop: 'admin_remark', width: 150, editType: 'input' },
        { label: '对方编码', prop: 'code', width: 200, editType: 'input' },
        {
          hide: props?.params?.from !== 'customerList',
          label: '币种',
          prop: 'currency',
          width: 160,
          editType: 'select',
          attrs: {
            options: objToArr(supportCurrency, false, 'id', 'title'),
          },
        },
        {
          hide: props?.params?.from !== 'customerList',
          label: '价格',
          prop: 'price',
          width: 180,
          editType: 'inputNumber',
        },
        {
          hide: props?.params?.from === 'customerList',
          label: '起订量(moq)',
          prop: 'moq',
          width: 120,
          editType: 'inputNumber',
        },
        {
          hide: props?.params?.from === 'customerList',
          label: 'EXW币种',
          prop: 'currency',
          width: 160,
          editType: 'select',
          attrs: {
            options: objToArr(supportCurrency, false, 'id', 'title'),
          },
        },
        // {
        //   label: '最新价格',
        //   prop: 'price',
        //   width: 150,
        //   editType: 'inputNumber',
        // },
        // { label: '历史最低', prop: 'lowest_price', width: 100 },
        // { label: '历史近期', prop: 'recently_price', width: 100 },
        // { label: '历史最高', prop: 'highest_price', width: 100 },
        {
          hide: props?.params?.from === 'customerList',
          label: 'EXW价格',
          prop: 'exw_price',
          width: 120,
          editType: 'inputNumber',
        },
        {
          hide: props?.params?.from === 'customerList',
          label: 'FOB币种',
          prop: 'fob_currency',
          width: 160,
          editType: 'select',
          attrs: {
            options: objToArr(supportCurrency, false, 'id', 'title'),
          },
        },
        {
          hide: props?.params?.from === 'customerList',
          label: 'FOB价格',
          prop: 'fob_price',
          width: 120,
          editType: 'inputNumber',
        },
        {
          hide: props?.params?.from === 'customerList',
          label: '厂商税率',
          prop: 'tax_rate',
          width: 150,
          editType: 'inputNumber',
          sufUnit: '%',
        },
        {
          hide: props?.params?.from === 'customerList',
          label: '价格策略',
          prop: 'product_price_policy_list',
          width: 240,
        },
        { label: '最近采购时间', prop: 'recently_purchase_time', width: 150 },
        {
          label: '网站',
          prop: 'website',
          width: 200,
          editType: 'editInput',
          attrs: {
            isLink: true,
          },
        },
        { label: '来源', prop: 'source', width: 150 },
        {
          label: '自动上传',
          editType: 'tag',
          tagTypeObj: PRODUCT_SOURCE_COLOR,
          prop: 'is_auto',
          sortable: 'custome',
          valFun: (row) => {
            return YES_NO[row.is_auto]
          },
          width: 120,
        },
        {
          width: 180,
          label: '操作',
          isOperate: true,
          fixed: 'right',
          action: {
            addPricePolicy: {
              condition: (row) => {
                return row?.product_price_policy_list?.length === 0
              },
            },
            lookPricePolicy: {
              condition: (row) => {
                return row?.product_price_policy_list?.length
              },
            },
            list: [
              'deleteSupplier',
              'deleteProduct',
              'addPricePolicy',
              'lookPricePolicy',
            ],
          },
        },
      ]

      const addProductDrawerAddSuccess = computed(
        () => store.getters['sys/addProductDrawerAddSuccess']
      )
      const tableRef = ref(null)
      const supplierDrawer = ref(null)
      const state = reactive({
        showDialog: false,
        showSupplierDrawer: false, // 供应商弹窗
        openData: {},
      })

      const dialogTitle = computed(
        () => `${state.openData?.supplier_info?.title}-价格策略`
      )

      let tableConfig = reactive({
        headerActions: {
          // 添加产品
          AddProduct: {
            condition: () => {
              return props.params?.from === 'supplier'
            },
          },
          // 添加供应商
          AddSupplier: {
            condition: () => {
              return props.params?.from === 'product'
            },
          },
          // 客户添加产品
          customerAddProduct: {
            condition: () => {
              return props.params?.from === 'customerList'
            },
          },
          // 客户添加产品
          customerProductSync: {
            condition: () => {
              return props.params?.from === 'customerList'
            },
            type: 'req',
            tips: '您确定要对当客户订单中的产品进行同步吗？',
            api: {
              update: `/${MODULE}/sync`,
              params: {
                customer_id: props?.info?.id,
              },
            },
            refreshDraw: true,
            refreshTable: true,
          },
          list: [
            'AddProduct',
            'AddSupplier',
            'exportF',
            'customerAddProduct',
            'customerProductSync', // 同步订单 报价单 产品
            'importF',
          ],
        },
        tableColumn: tableColumns,
        tableListApi: `/${MODULE}/index`,
        tableUpdateApi: `/${MODULE}/edit`,
        tableDeleteApi: `/${MODULE}/delete`,
        tableExportApi: `/${MODULE}/export`,
        useField: false,
        tableFileImport: {
          refreshTable: true,
          action: `/${MODULE}/import`,
        },
        tableListParams: {
          sort: 'id',
          order: 'desc',
          [props.params?.searchKey || '']:
            props.info[props.params?.valKey || ''] || '',
        },
        tableDeleteParams: {},
        tableButtonNum: 3,
        tableSearch: customerSupplierProductSearchConfig(
          props.params?.from,
          props.info
        ),
      })

      const handleHeaderAction = (action) => {
        if (action.code === 'AddProduct') {
          store.commit('sys/setShowAddProductDrawer', 'supplierList')
          store.commit('sys/setAddProductDrawerParams', {
            info: props.info,
            addNow: true,
          })
        } else if (action.code === 'AddSupplier') {
          state.showSupplierDrawer = true
          store.commit('sys/setDetailDrawerSize', '61.9%')
          nextTick(() => {
            supplierDrawer.value.visible = true
          })
        } else if (action.code === 'customerAddProduct') {
          // 客户添加产品
          store.commit('sys/setShowAddProductDrawer', 'customerList')
          store.commit('sys/setAddProductDrawerParams', {
            info: props.info,
            addNow: true,
          })
        }
      }

      const handlClose = () => {
        store.commit('sys/setDetailDrawerSize', '80%')
      }

      // 表格操作
      const handleTableAction = (action, row) => {
        console.log('action :>> ', action)
        console.log('row :>> ', row)
        if (
          action.code === 'addPricePolicy' ||
          action.code === 'lookPricePolicy'
        ) {
          state.openData = row
          nextTick(() => {
            state.showDialog = true
          })
        }
      }

      // 刷新数据
      const handleRefresh = () => {
        tableRef?.value?.initSearch()
      }
      watchEffect(() => {
        if (addProductDrawerAddSuccess.value) {
          handleRefresh()
        }
      })
      return {
        CURRENCY_UNIT,
        ...toRefs(state),
        dialogTitle,
        tableConfig,
        tableRef,
        supplierDrawer,
        handleHeaderAction,
        handleRefresh,
        handleTableAction,
        handlClose,
        t,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
