<!--
 * @Author: WGL
 * @Date: 2022-02-15 18:40:13
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2024-12-06 10:34:45
 * @Description: 
-->
<template>
  <div v-if="itemOrMenu.meta && itemOrMenu.meta.levelHidden">
    <template v-for="route in itemOrMenu.children" :key="route.path">
      <vab-menu :item="route" />
    </template>
  </div>
  <el-sub-menu v-else :index="itemOrMenu.path" teleported>
    <template #title>
      <div class="fxmj w">
        <vab-icon
          v-if="itemOrMenu.meta && itemOrMenu.meta.icon"
          :icon="itemOrMenu.meta.icon"
          :is-custom-svg="itemOrMenu.meta.isCustomSvg"
          :title="translateTitle(itemOrMenu.meta.title)"
        />
        <span
          v-show="!collapse || itemOrMenu.pid != 0"
          class="ex"
          :title="translateTitle(itemOrMenu.meta.title)"
        >
          {{ translateTitle(itemOrMenu.meta.title) }}
        </span>
        <span
          v-if="itemOrMenu.meta && itemOrMenu.meta.dot"
          class="vab-dot vab-dot-error"
        >
          <span />
        </span>
      </div>
    </template>
    <slot />
  </el-sub-menu>
</template>

<script>
  import { defineComponent, computed } from 'vue'
  import { translateTitle } from '@/utils/i18n'
  import { useStore } from 'vuex'

  export default defineComponent({
    name: 'VabSubMenu',
    props: {
      itemOrMenu: {
        type: Object,
        default() {
          return null
        },
      },
    },
    setup() {
      const store = useStore()
      const collapse = computed(() => store.getters['settings/collapse'])
      return {
        translateTitle,
        collapse,
      }
    },
  })
</script>
