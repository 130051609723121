<!--
 * @Author: WGL
 * @Date: 2023-06-21 09:23:33
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2024-12-10 10:59:32
 * @Description: 
-->
<template>
  <div>
    <div>
      <span>{{ volumeText }} {{ volumeUnit }}</span>
      <span class="plr2">/</span>
      <span>{{ volumeTextTransform }}</span>
    </div>
    <div>
      {{ numberToFixed(info?.weight, 3) || '-' }}
      {{ info?.display_weight_unit || 'kg' }}
    </div>
    <div>
      {{ info?.l }}L-{{ info?.w }}W-{{ info?.h }}H
      {{ info?.display_length_unit || 'cm' }}
    </div>
  </div>
</template>

<script>
  // 基础产品规格组件
  import { defineComponent, computed } from 'vue'
  import { numberToFixed, convertVolume, handleVolume } from '@/utils/index'
  export default defineComponent({
    name: 'BaseSingleSpecs',
    components: {},
    props: {
      info: { type: Object, default: () => {} },
    },
    setup(props) {
      const defaultUnit = computed(() => {
        return props.info?.display_length_unit || 'cm'
      })
      const volumeUnit = computed(() => {
        return defaultUnit.value === 'cm' ? 'm³' : 'ft³'
      })
      const volumeText = computed(() => {
        return handleVolume(props.info, defaultUnit.value)
      })

      const volumeTextTransform = computed(() => {
        return convertVolume(volumeText.value, defaultUnit.value, true)
      })
      return {
        numberToFixed,
        volumeUnit,
        volumeText,
        volumeTextTransform,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
