<!--
 * @Author: WGL
 * @Date: 2023-07-27 11:12:06
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2024-12-06 10:31:47
 * @Description:
-->
<template>
  <!-- component标签：可以动态便绑定我们的组件，根据数据不同跟换不同组件 -->
  <component
    :is="menuComponent"
    v-if="menuItemInfo.meta && !menuItemInfo.meta.hidden"
    :item-or-menu="menuItemInfo"
  >
    <template v-if="menuItemInfo.children && menuItemInfo.children.length">
      <el-scrollbar
        v-if="
          (layout === 'horizontal' && menuItemInfo.children.length > 18) ||
          (layout !== 'horizontal' &&
            collapse &&
            menuItemInfo.children.length > 18)
        "
        class="vab-menu-children-height"
      >
        <vab-menu
          v-for="route in menuItemInfo.children"
          :key="route.path"
          :item="route"
        />
      </el-scrollbar>
      <template v-else>
        <vab-menu
          v-for="route in menuItemInfo.children"
          :key="route.path"
          :item="route"
        />
      </template>
    </template>
  </component>
</template>

<script>
  import { computed, defineComponent } from 'vue'
  import { useStore } from 'vuex'

  export default defineComponent({
    name: 'VabMenu',
    props: {
      item: {
        type: Object,
        required: true,
      },
      layout: {
        type: String,
        default: '',
      },
    },
    setup(props) {
      const store = useStore()
      const collapse = computed(() => store.getters['settings/collapse'])
      const menuBadge = computed(() => store.getters['sys/menuBadge'])
      const menuBadgePid = computed(() => store.getters['sys/menuBadgePid'])
      const menuItemInfo = computed(() => {
        const { id } = props.item
        return {
          ...props.item,
          meta: {
            ...props.item.meta,
            badge: menuBadge.value[id] || 0,
            dot: !!menuBadgePid.value[id],
          },
        }
      })
      const menuComponent = computed(() =>
        props.item.children &&
        props.item.children.some((_route) => {
          return _route.meta && _route.meta.hidden !== true
        })
          ? 'VabSubMenu'
          : 'VabMenuItem'
      )
      return {
        collapse,
        menuItemInfo,
        menuComponent,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-menu-children-height {
    height: 60vh !important;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }
</style>

<!--由于element-plus
bug使用popper-append-to-body=false会导致多级路由无法显示，故所有菜单必须生成至body下，样式必须放到body下-->
<style lang="scss">
  .el-popper.is-light {
    border: 0 !important;
  }
</style>
