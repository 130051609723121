<!--
 * @Author: WGL
 * @Date: 2023-10-31 10:15:08
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2024-12-06 10:49:20
 * @Description: 
-->
<template>
  <el-tooltip :content="currentVersion" effect="dark" placement="bottom">
    <vab-icon icon="copyright-line" />
  </el-tooltip>
</template>

<script>
  import { defineComponent, computed } from 'vue'
  import { version } from '@/config'
  export default defineComponent({
    name: 'VabCopyright',
    setup() {
      const currentVersion = computed(() => {
        return `当前版本：${version}`
      })
      return {
        currentVersion,
      }
    },
  })
</script>
