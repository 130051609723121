<template>
  <div class="system-user-container">
    <base-table
      ref="tableRef"
      :config="tableConfig"
      :ref-menu-id="refMenuId"
      table-height="72vh"
      @get-detail-success="getDetailSuccess"
      @header-action="handleHeaderAction"
    >
      <!-- 规格 -->
      <template #sigleSpecs="{ scope }">
        <base-single-specs :info="scope" />
      </template>
      <template #supplierList="{ scope }">
        <div>
          <div v-for="(item, index) in scope.supplier_list" :key="index">
            {{ item.title }}
          </div>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
  import { defineComponent, reactive, onBeforeMount, ref, toRefs } from 'vue'
  import {
    PRODUCT_CARTON_TYPE,
    YES_NO,
    PRODUCT_SOURCE_COLOR,
  } from '@/config/variable.config'
  import { t } from '@/utils/i18n'
  import { productCartonAddDialog } from '@/utils/dialogBoxConfig'
  import { commonSearchConfig } from '@/utils/headerSearchConfig'
  import { numberToFixed } from '@/utils/index'
  const MODULE = 'product-carton' // 模块

  export default defineComponent({
    name: 'ProductCartonList',
    props: {
      params: { type: Object, default: () => {} },
      info: { type: Object, default: () => {} },
      refMenuId: { type: [Number, String], default: '' }, // 关联的菜单ID
    },
    emits: [],
    setup(props) {
      // 表格数据
      const tableColumns = [
        // { type: 'selection', width: 50, fixed: 'left' },
        { label: 'ID', prop: 'id', width: 85, sortable: 'custome' },
        {
          hide: props?.info?.id,
          label: '关联产品',
          prop: 'product_info.title',
          minWidth: 150,
          class: 'cp cb tdu',
          publicDrawerConfig: {
            openName: 'productDetail',
            api: {
              valueKey: 'product_id',
            },
          },
        },
        { label: '名称', prop: 'title', width: 180 },
        {
          label: '类型',
          prop: 'type',
          width: 150,
          valFun: (row) => {
            return PRODUCT_CARTON_TYPE[row.type]
          },
        },
        {
          label: '规格',
          prop: 'sigleSpecs',
          width: 200,
          tips: '体积/净重/长-宽-高',
        },
        { label: '供应商', prop: 'supplierList', width: 300 },
        {
          label: '默认箱规',
          prop: 'is_default',
          width: 90,
          editType: 'tag',
          tagTypeObj: PRODUCT_SOURCE_COLOR,
          valFun: (row) => {
            return YES_NO[row.is_default]
          },
        },
        {
          label: '费用箱规',
          tips: '计算货品体积费用时的箱规',
          prop: 'is_storage_volume',
          width: 110,
          editType: 'tag',
          tagTypeObj: PRODUCT_SOURCE_COLOR,
          valFun: (row) => {
            return YES_NO[row.is_storage_volume]
          },
        },
        { label: '备注', prop: 'admin_remark', class: 'omit', minWidth: 130 },
        { label: '创建人', prop: 'creator.name', width: 130 },
        { label: '创建时间', prop: 'create_time', width: 180 },
        {
          width: 150,
          label: '操作',
          isOperate: true,
          fixed: 'right',
          action: {
            edit: {
              type: 'editBox',
            },
            list: ['edit', 'delete'],
          },
        },
      ]

      const state = reactive({})
      const tableRef = ref(null)

      let tableConfig = reactive({
        searchBtnInline: true,
        headerActions: {
          add: {
            type: 'editBox',
          },
          list: ['add'],
        },
        tableColumn: tableColumns,
        tableListApi: `/${MODULE}/index`,
        tableListParams: {
          sort: 'id',
          order: 'desc',
          [props?.params?.searchKey || '']: props?.info?.id || '',
        },
        tableUpdateApi: `/${MODULE}/edit`,
        tableDeleteApi: `/${MODULE}/delete`,
        tableExportApi: `/${MODULE}/export`,
        tableFileImport: {
          refreshTable: true,
          action: `/${MODULE}/import`,
        },
        tableExportPathKey: 'http_path',
        tableDeleteParams: {},
        tableButtonNum: 3,
        tableSearch: commonSearchConfig('product'),
        editBox: productCartonAddDialog(
          MODULE,
          props.params?.from || '',
          props.info,
          props.params
        ),
      })

      const handleHeaderAction = (action) => {
        console.log('action', action)
      }

      onBeforeMount(async () => {})
      return {
        ...toRefs(state),
        tableConfig,
        tableRef,
        handleHeaderAction,
        numberToFixed,
        t,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .system-user-container {
    height: 100%;
    :deep(.input-number) {
      .el-input__inner {
        padding-right: 0;
      }
    }
  }
</style>
