<template>
  <div class="system-user-container">
    <base-table
      ref="tableRef"
      :config="tableConfig"
      :ref-menu-id="refMenuId"
      table-height="auto"
      @header-action="handleHeaderAction"
      @table-action="handleTableAction"
    >
      <template #header-button-after>
        <div class="ex fxmr">
          <div class="fs20 cp" :class="''">
            <jwt-icon
              :class="[showGrid ? 'cb' : '']"
              icon="grid-fill"
              @click="handleGrid"
            />
            <jwt-icon
              class="ml10"
              :class="[!showGrid ? 'cb' : '']"
              icon="list-unordered"
              @click="handleGrid"
            />
          </div>
        </div>
      </template>
      <template #table-content="{ list }" v-if="showGrid">
        <el-row class="file-grid-wrapper mt10 fx fxwp" :gutter="16">
          <el-col v-for="(item, index) in list" :key="index" :span="3">
            <div class="file-grid-item bor r5px p5 mb15">
              <div
                class="file-grid-item__img fxmc cp"
                @click="showPreview(item)"
              >
                <el-image
                  v-if="item?.fileInfo?.file === 'img'"
                  fit="fill"
                  :preview-src-list="[item.org_url]"
                  :src="item.url"
                />
                <jwt-icon
                  v-else
                  class="file-grid-item__fileIcon"
                  :class="item.fileInfo.cls"
                  :icon="item?.fileInfo?.icon"
                />
              </div>
              <div class="w omit ptb10 cp" :title="item.title">
                {{ item.title }}
              </div>
              <div>
                <base-table-btn
                  :config="btnConfig"
                  :detail-info="item"
                  :show-button-num="3"
                  url-path="/public/fileList"
                  @table-action="handleTableAction2($event, item)"
                />
              </div>
            </div>
          </el-col>
        </el-row>
      </template>
      <template #title="{ scope }">
        <div class="cp cb tdu" @click="showPreview(scope)">
          {{ scope.title || scope.file_path }}
        </div>
      </template>
      <template #fileUpload="{ model }">
        <el-form-item :label="t('上传文件')">
          <JwtFileUpload
            class="w"
            copy-img-uploade
            limit="1"
            :module="params?.module"
            :translate-title="t"
            :upload-file-url="uploadFileUrl"
            @upload-success="
              (file, refresh) => handleUploadFileSuccess2(file, refresh, model)
            "
          />
        </el-form-item>
      </template>
    </base-table>

    <el-dialog
      v-model="showUpload"
      append-to-body
      center
      destroy-on-close
      title="上传文件"
      width="45%"
    >
      <el-form
        label-position="left"
        label-width="120px"
        style="margin-top: 20px"
      >
        <!-- <FileUpload
          :id="id"
          limit="10"
          :module="params?.module"
          @on-success="handleUploadSuccess"
        /> -->
        <JwtFileUpload
          limit="10"
          :module="params?.module"
          :translate-title="t"
          :upload-file-url="uploadFileUrl"
          @on-success="handleUploadSuccess"
          @upload-success="handleUploadFileSuccess"
        />
      </el-form>
    </el-dialog>
    <Previewer ref="previewerRef" />
  </div>
</template>

<script>
  import {
    defineComponent,
    reactive,
    onBeforeMount,
    ref,
    toRefs,
    computed,
  } from 'vue'
  import { fillUrl, download } from '@/common/js/utils'

  import { getFileIcon } from 'jwt-ui'
  import { t } from '@/utils/i18n'
  import request from '@/utils/request'
  import { baseURL } from '@/config/index'
  import { handleFileSize } from '@/utils/index'
  import { fileListAddDialog } from '@/utils/dialogBoxConfig'
  // 表格数据

  const MODULE = 'file' // 模块
  const btnConfig = {
    action: {
      rename: {
        type: 'editBox',
        editBoxName: 'editBoxRename',
      },
      list: ['rename', 'deleteFile', 'download'],
    },
  }
  const tableColumns = [
    { label: 'ID', prop: 'id', width: 80 },
    { label: '附件名称(点击预览)', prop: 'title' },
    { label: '关键字', prop: 'keywords', width: 120 },
    { label: '描述', prop: 'description', width: 120 },
    {
      label: '附件类型',
      prop: 'type',
      width: 100,
      valFun: (row) => {
        if (row.url) {
          return '网页'
        }
        return row.type == 'image' ? '图片' : '文件'
      },
    },
    { label: '页数', prop: 'pages', width: 100 },
    {
      label: '附件大小',
      prop: 'size',
      width: 100,
      valFun: (row) => {
        return handleFileSize(row.size)
      },
    },
    { label: '上传人', prop: 'create_admin_info.name', width: 160 },
    { label: '上传时间', prop: 'create_time', width: 180 },
    {
      width: 150,
      label: '操作',
      isOperate: true,
      fixed: 'right',
      ...btnConfig,
    },
  ]
  export default defineComponent({
    name: 'FileList',
    components: {},
    props: {
      info: { require: true, type: Object, default: () => {} },
      params: { type: Object, default: () => {} },
      refMenuId: { type: [Number, String], default: '' }, // 关联的菜单ID
    },
    setup(props) {
      // const fileModule = computed(() => {
      //   return props.info?.module || props.params?.module || props.module
      // })

      const uploadFileUrl = baseURL + '/company/config' // 获取cos配置信息

      const state = reactive({
        showUpload: false,
        followcondition: [],
        showGrid: true,
      })
      const previewerRef = ref(null)

      const handleHeaderAction = (action) => {
        console.log('action', action)
        // if (action.code === 'upload') {
        //   state.showUpload = true
        // }
      }

      // 列表按钮
      const handleTableAction = (action, row, rowAction) => {
        console.log('action :>> ', action)
        console.log('row :>> ', row)
        console.log('rowAction :>> ', rowAction)
        if (action.code === 'download') {
          const url = fillUrl(row.file_path)
          download(url, row.title)
        }
      }

      const handleTableAction2 = (action, item) => {
        console.log('action', action)
        console.log('item', item)
        if (action.code === 'download') {
          const url = fillUrl(item.file_path)
          // debugger
          download(url, item.title)
          return
        }
        tableRef.value.jwtTableRef.handleTableAction(
          { action: action },
          item,
          btnConfig
        )
      }

      const showPreview = (row) => {
        if (row?.fileInfo?.file === 'img' && state.showGrid) return
        previewerRef.value?.show(row.file_path, row.extension, row.title)
      }

      const tableRef = ref(null)

      // 刷新列表
      const handleRefreshList = () => {
        state.showUpload = false
        tableRef.value.initSearch()
      }

      const handleUploadSuccess = (data) => {
        if (data.status == 'success') {
          handleRefreshList()
        }
      }
      const id = computed(() => {
        return props?.info?.id || 0
      })

      const tableConfig = reactive({
        headerActions: {
          upload: {
            type: 'editBox',
          },
          list: ['upload'],
        },
        useField: false,
        tableColumn: tableColumns,
        tableButtonNum: 3,
        tableListApi: `/${MODULE}/index`,
        tableListParams: {
          sort: 'id',
          order: 'desc',
          ref_id: id,
          per_page: 10,
          module: props.params?.module,
        },
        tableDeleteApi: `/${MODULE}/delete`,
        tableUpdateApi: `/${MODULE}/edit`,
        tableDeleteParams: {},
        tableListCallback: (list = []) => {
          return list.map((item) => {
            item.url = fillUrl(item.file_path, 500, 500, 50)
            item.org_url = fillUrl(item.file_path)
            item.fileInfo = getFileIcon(item.file_path)
            return item
          })
        },
        editBox: fileListAddDialog(MODULE, 'upload', props.info, props.params),
        editBoxRename: fileListAddDialog(
          MODULE,
          'rename',
          props.info,
          props.params
        ),
      })

      const handleUploadFileSuccess = (file, refresh = false) => {
        // 附件保存
        console.log('file :>> handleUploadFileSuccess', file)
        request({
          url: `/file/uploadFile`,
          method: 'post',
          data: {
            id: id.value,
            module: props.params.module,
            name: file.name,
            size: file.size,
            path: '/' + file.keyPath,
          },
        }).then(() => {
          if (refresh) {
            handleRefreshList()
          }
        })
      }

      const handleUploadFileSuccess2 = (file, refresh = false, model) => {
        console.log('refresh', refresh)
        model.name = file.name
        model.size = file.size
        model.path = '/' + file.keyPath
        if (!model.title) {
          model.title = file.name
        }
      }

      const handleGrid = () => {
        state.showGrid = !state.showGrid
      }

      onBeforeMount(async () => {})

      return {
        uploadFileUrl,
        btnConfig,
        id,
        tableRef,
        previewerRef,
        tableConfig,
        ...toRefs(state),
        fillUrl,
        handleTableAction,
        handleHeaderAction,
        t,
        handleUploadSuccess,
        showPreview,
        handleUploadFileSuccess,
        handleUploadFileSuccess2,
        handleGrid,
        handleTableAction2,
        // fileModule,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .system-user-container {
    height: 100%;
    .file-grid-wrapper {
      .file-grid-item {
        &__img {
          width: 100%;
          aspect-ratio: 1/1;
          .video {
            color: #1e90ff;
            // color: #007aff;
          }
          .audio {
            color: #e60726;
          }
          .pdf {
            color: #f54d3b;
          }
          .ppt {
            color: #cf6738;
          }
          .word {
            color: #007aff;
          }
          .excel {
            color: #25bb7d;
          }
        }
        &__fileIcon {
          font-size: 100px;
        }
      }
    }
  }
</style>
