<!--
 * @Author: WGL
 * @Date: 2023-05-16 17:10:08
 * @LastEditors: WGL
 * @LastEditTime: 2024-11-26 10:40:31
 * @Description:
-->
<template>
  <el-drawer
    v-model="visible"
    destroy-on-close
    direction="ltr"
    :modal="showModal"
    :modal-class="[showModal ? '' : 'add-product-modal-class']"
    :show-close="true"
    :size="showModal ? '38%' : '100%'"
    :with-header="false"
    @close="handleClose"
  >
    <div
      class="system-user-container rel"
      :class="[showModal ? '' : 'pr15 pt15']"
    >
      <!-- 防止右侧输入框不能聚焦 -->
      <el-input
        ref="hideValRef"
        v-model="hideVal"
        class="hideValRef"
        clearable
        placeholder=""
        size="small"
      />
      <base-table
        ref="refTable"
        class="addProductDrawerTableWrapper"
        :config="tableConfig"
        page-url="/product/productList"
        table-height="70vh"
        @header-action="handleHeaderAction"
        @selection-change="handleSelectionChange"
        @table-action="handleTableAction"
      >
        <!-- <template #header-button-before>
          <el-button
            :disabled="!selectList.length"
            size="default"
            type="primary"
            @click="handleAddBatch"
          >
            批量添加
          </el-button>
        </template> -->
        <template #code="{ scope }">
          <base-code :info="scope?.product_info || scope || {}" />
        </template>
        <template #imageTag="{ scope }">
          <BaseImageTag :info="scope?.product_info || scope || {}" />
        </template>
        <!-- SKU -->
        <template #sku="{ scope }">
          <BaseSku :info="scope" />
        </template>
        <!-- 家具仓库数量 -->
        <template #furnitureNum="{ scope }">
          <BaseFurnitureStock :info="scope.mhl_stock" />
        </template>

        <template #action-before="{ scope }">
          <el-button
            :disabled="handleBtnDisable(scope)"
            link
            type="primary"
            @click="handleAddProduct(scope)"
          >
            {{ handleBtnText(scope) }}
          </el-button>
        </template>
      </base-table>
      <el-button
        v-if="!showModal"
        class="add-product-close-btn"
        :icon="Close"
        link
        @click.stop="handleCloseDrawer"
      />
    </div>
  </el-drawer>
</template>

<script>
  import {
    computed,
    defineComponent,
    reactive,
    ref,
    toRefs,
    onMounted,
    markRaw,
  } from 'vue'
  import { useStore } from 'vuex'
  import request from '@/utils/request'
  import { Close } from '@element-plus/icons-vue'
  import { t } from '@/utils/i18n'
  import { productAddSearchConfig } from '@/utils/headerSearchConfig'
  import { productFurnitureCopyAddDialog } from '@/utils/dialogBoxConfig'
  import {
    furnitureRepositoryStatus,
    furnitureRepositoryStatusColor,
    EQUIPMENT_STATUS_COLOR,
    EQUIPMENT_STATUS,
  } from '@/config/variable.config'
  import { productDesTable } from '@/utils/desTableConfig'

  import { PRODUCT_PATH } from '@/config/business.config'

  const MODULE_PRODUCT = 'product' // 模块
  import ProductDetailBase from '@/views/product/components/productDetailBase.vue' // 基本信息
  import ProductRepository from '@/views/repository/components/productRepository.vue' // 库存
  // import ProductBaseList from '@/componentsBusiness/productBaseList.vue' // 产品列表明细
  import ProductPackageList from '@/views/product/productPackageList.vue' // 配套产品列表
  import ProductSupplier from '@/views/purchase/components/productSupplier.vue' // 供应商
  import FollowUpList from '@/views/publicModule/followUp.vue' // 跟进
  import FileList from '@/views/publicModule/fileList.vue' // 附件
  import FeedbackList from '@/views/sale/feedbackList.vue' // 客诉列表
  import ProductChannelManage from '@/views/product/components/productChannelManage.vue' // 渠道管理
  import ProductCartonList from '@/views/product/cartonSize/index.vue' // 产品箱规

  export default defineComponent({
    name: 'AddProductDrawers',
    components: {},
    props: {
      module: { require: true, type: String, default: 'quote' },
      info: { require: true, type: Object, default: () => {} },
      addNow: { type: Boolean, default: false }, // 是否立即添加
      showModal: { type: Boolean, default: true }, // 是否显示遮罩层
    },
    emits: ['add-product', 'close', 'batch-add'],
    setup(props, { emit }) {
      const refTable = ref(null)
      const hideValRef = ref(null)
      const store = useStore()
      const isAutoParts = computed(() => store.getters['sys/isAutoParts'])

      const state = reactive({
        selectList: [],
        visible: false,
        init: true,
        hideVal: '',
      })

      const addProductDrawerSelect = computed(
        () => store.getters['sys/addProductDrawerSelect']
      )

      const selectListIds = computed(() => {
        return store.getters['sys/addProductDrawerSelect'].map(
          (item) => item.id
        )
      })

      const deleteProductSelectInfo = computed(
        () => store.getters['sys/deleteProductSelectInfo']
      )
      const userMenusAuthObj = computed(() => store.getters['routes/menusObj'])

      // 用户的列表字段
      const userFieldList = computed(() => store.getters['sys/userFieldList'])

      console.log('props :>> AddProductDrawer', props)

      const apiModuleObj = {
        // 发货计划
        deliveryPlan: {
          listApi: 'sale-order-detail',
          addApi: 'delivery-plan-detail',
          addParamsKey: 'plan_id',
          module: '',
          searchKey: 'order_id',
          productKey: 'product_id',
          searchId: props.info?.sale_order_id,
          addStatusKey: '__delivery_plan_id',
          imgProp: 'product_info.image',
          titleProp: 'product_info.title',
          codeProp: 'product_info.oem_code',
          sorts: {
            rank: 'asc',
          },
        },
        // 发货单
        supplierDeliveryAdd: {
          listApi: 'product',
          addApi: 'supplier-delivery-detail',
          addParamsKey: 'supplier_delivery_id',
          module: '',
          searchKey: '__supplier_delivery_id',
          productKey: 'id',
          searchId: props.info?.id,
          addStatusKey: '__supplier_delivery_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
        },
        // 发货计划
        deliveryPlanAdd: {
          listApi: 'sale-order-detail',
          addApi: 'delivery-plan-detail',
          addParamsKey: 'plan_id',
          module: '',
          searchKey: 'order_id',
          productKey: 'product_id',
          searchId: props.info?.id,
          addStatusKey: '',
          imgProp: 'product_info.image',
          titleProp: 'product_info.title',
          codeProp: 'product_info.oem_code',
          numProp: 'deliveryPlanAdd',
          sorts: {
            rank: 'asc',
          },
        },
        // 出入库
        repositoryRecord: {
          listApi: 'product',
          addApi: 'repository-receipt-detail',
          addParamsKey: 'receipt_id',
          module: '',
          searchKey: '__repository_receipt_id',
          productKey: 'id',
          searchId: props.info?.id,
          addStatusKey: '__repository_receipt_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
        },
        // 销售出入库
        repositoryRecordSales: {
          listApi: 'sale-order-detail',
          addApi: 'repository-receipt-detail',
          addParamsKey: 'receipt_id',
          module: '',
          searchKey: 'order_id',
          productKey: 'product_id',
          searchId: props.info?.id,
          addStatusKey: '__repository_receipt_id',
          imgProp: 'product_info.image',
          titleProp: 'product_info.title',
          codeProp: 'product_info.oem_code',
          sorts: {
            rank: 'asc',
          },
        },
        // 采购订单
        purchase: {
          listApi: 'product',
          addApi: 'purchase-order-detail',
          addParamsKey: 'order_id',
          module: '',
          searchKey: '__purchase_id',
          productKey: 'id',
          searchId: props.info?.id,
          addStatusKey: '__purchase_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
        },
        // 采购出入库
        repositoryRecordPurchase: {
          listApi: 'purchase-order-detail',
          addApi: 'repository-receipt-detail',
          addParamsKey: 'receipt_id',
          module: '',
          searchKey: 'order_id',
          productKey: 'product_id',
          searchId: props.info?.id,
          addStatusKey: '__repository_receipt_id',
          imgProp: 'product_info.image',
          titleProp: 'product_info.title',
          codeProp: 'product_info.oem_code',
          sorts: {
            rank: 'asc',
          },
        },
        // 产品盘点
        inventoryProduct: {
          listApi: 'product',
          addApi: 'repository-inventory-detail',
          addParamsKey: 'repository_inventory_id',
          module: '',
          searchKey: '__repository_inventory_id',
          productKey: 'id',
          searchId: props.info?.id,
          addStatusKey: '__repository_inventory_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
        },
        // 报价单
        quoteList: {
          listApi: 'product',
          addApi: 'quote-detail',
          addParamsKey: 'quote_id',
          module: '',
          searchKey: '__quote_id',
          productKey: 'id',
          searchId: props.info?.id,
          addStatusKey: '__quote_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
        },
        // 订单
        orderList: {
          listApi: 'product',
          addApi: 'sale-order-detail',
          addParamsKey: 'order_id',
          module: '',
          searchKey: '__order_id',
          productKey: 'id',
          searchId: props.info?.id,
          addStatusKey: '__order_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
        },
        // 供应商
        supplierList: {
          listApi: 'product',
          addApi: 'supplier-product',
          addParamsKey: 'supplier_id',
          module: '',
          searchKey: '__supplier_id',
          productKey: 'id',
          searchId: props.info?.id,
          addStatusKey: '__supplier_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
        },
        // 平替产品
        productReplaceList: {
          listApi: 'product',
          addApi: 'product-replace',
          addParamsKey: 'ref_product_id',
          module: '',
          searchKey: '__replace_product_id',
          productKey: 'id',
          searchId: props.info?.id,
          addStatusKey: '__replace_product_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
        },
        // 产品包
        productPackageList: {
          listApi: 'product',
          addApi: 'product-package-content',
          addParamsKey: 'package_id',
          module: '',
          searchKey: '__product_package_id',
          productKey: 'id',
          searchId: props.info?.id,
          addStatusKey: '__product_package_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
        },
        // 产品调拨
        productTransfer: {
          listApi: 'product',
          addApi: 'repository-transfer-detail', // 添加接口
          addParamsKey: 'transfer_id', // 添加参数
          module: '',
          searchKey: 'repository_id', // 搜索参数
          productKey: 'id',
          searchId:
            props.info?.repository_id || props.info?.out_repository_id || '',
          addStatusKey: '__repository_transfer_id', // 判断是否已添加key
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
        },
        // 产品组装拆卸
        productAssemble: {
          listApi: 'product',
          addApi: 'repository-assemble-detail', // 添加接口
          addParamsKey: 'assemble_id', // 添加参数
          module: '',
          searchKey: 'id|<>', // 搜索参数
          productKey: 'id', // 产品的id
          searchId: props.info?.product_id || '',
          addStatusKey: '__repository_assemble_id', // 判断是否已添加key
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
        },
        productConstituteList: {
          listApi: 'product',
          addApi: 'product-compound-template-content', // 添加接口
          addParamsKey: 'master_product_id', // 添加参数
          module: '',
          searchKey: 'id|<>', // 搜索参数
          productKey: 'id', // 产品的id
          searchId: props.info?.id || '',
          addStatusKey: '__master_product_id', // 判断是否已添加key
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
        },
        addProduct: {
          listApi: 'product',
          addApi: 'quote-detail',
          addParamsKey: 'quote_id',
          module: '',
          searchKey: '__currency',
          productKey: 'id',
          searchId: props.info?.currency,
          addStatusKey: '__quote_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
        },
        // 供应商出库单
        stockOutAdd: {
          listApi: 'product',
          addApi: 'delivery-plan-detail',
          addParamsKey: 'plan_id',
          module: '',
          searchKey: '__plan_id',
          productKey: 'id',
          searchId: props.info?.id,
          addStatusKey: '__plan_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
        },
        // 供货商退货
        salesReturn: {
          listApi: 'product',
          addApi: 'supplier-sale-return-detail',
          addParamsKey: 'return_id',
          module: '',
          searchKey: '',
          productKey: 'id',
          searchId: props.info?.id,
          addStatusKey: '__supplier_sale_return_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'num',
        },
        salesClaim: {
          listApi: 'product',
          addApi: 'supplier-sale-return-detail',
          addParamsKey: 'supplier_sale_id',
          module: '',
          searchKey: '',
          productKey: 'id',
          searchId: '',
          addStatusKey: 'plan_id',
          imgProp: 'product_info.image',
          titleProp: 'product_info.title',
          codeProp: 'product_info.oem_code',
          numProp: 'num',
        },
        // 客户
        customerList: {
          listApi: 'product',
          addApi: 'customer-product',
          addParamsKey: 'customer_id',
          module: '',
          searchKey: '__customer_id',
          productKey: 'id',
          searchId: props.info?.id,
          addStatusKey: '__customer_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
        },
        // BOM
        bomList: {
          listApi: 'product',
          addApi: 'bom-detail',
          addParamsKey: 'bom_id',
          module: '',
          searchKey: 'nature|>',
          productKey: 'id',
          searchId: 0,
          addStatusKey: '__bom_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
        },
        // 工艺
        technologyList: {
          listApi: 'process',
          addApi: 'technology-process',
          addParamsKey: 'technology_id',
          moduleKey: 'process_id',
          module: '',
          productKey: 'id',
          addStatusKey: '__technology_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
          tableColumns: [
            { label: '工序名称', prop: 'title', minWidth: 150 },
            { label: '负责人', prop: 'mainer.name', width: 130 },
            { label: '单位', prop: 'unit', width: 70 },
            { label: '单价', prop: 'price', width: 130, preUnit: '￥' },
            { label: '加工时长', prop: 'spend', width: 130, sufUnit: 's' },
            {
              width: 70,
              label: '操作',
              prop: 'operation',
              fixed: 'right',
              action: {
                list: ['AddProcess'],
              },
            },
          ],
        },
        // 领料单明细
        addPickingMateriel: {
          listApi: 'product',
          addApi: 'material-receipt-detail',
          addParamsKey: 'material_receipt_id',
          module: '',
          searchKey: 'nature|>',
          productKey: 'id',
          searchId: 0,
          addStatusKey: '__material_receipt_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
        },
        // 退料单详情
        addReturnMateriel: {
          listApi: 'material-receipt-detail',
          addApi: 'material-receipt-detail',
          addParamsKey: 'material_receipt_id',
          module: '',
          searchKey: 'material_receipt_id',
          productKey: 'product_id',
          searchId: props.info?.pid,
          addStatusKey: '__material_receipt_id',
          imgProp: 'product_info.image',
          titleProp: 'product_info.title',
          codeProp: 'product_info.oem_code',
          sorts: {
            rank: 'asc',
          },
        },
        // 生产工单添加物料
        addProductionOrderMaterial: {
          listApi: 'product',
          addApi: 'material-requirement',
          addParamsKey: 'production_receipt_id',
          moduleKey: 'material_id',
          module: '',
          searchKey: 'nature|>',
          productKey: 'id',
          searchId: 1,
          addStatusKey: '___production_receipt_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
        },
        // 三方店铺
        thirdShop: {
          listApi: 'product',
          addApi: 'product-business',
          addParamsKey: 'id',
          module: '',
          searchKey: '',
          productKey: 'id',
          searchId: '',
          addStatusKey: '',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
        },
        // 家具订单
        furnitureOrder: {
          listApi: 'product',
          addApi: 'sale-order-detail',
          addParamsKey: 'order_id',
          module: '',
          searchKey: '__order_id',
          productKey: 'id',
          searchId: props.info?.id,
          addStatusKey: '__order_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
        },
        // 家具备货
        furnitureStockup: {
          listApi: 'purchase-order-detail',
          addApi: 'delivery-plan-detail',
          addParamsKey: 'id',
          module: '',
          searchKey: 'status',
          moduleKey: 'purchase_order_detail_id',
          productKey: 'id',
          searchId: [15, 35, 65], // 国内仓 荔枝角 深水涉
          addStatusKey: '__delivery_plan_detail_id',
          imgProp: 'product_info.image',
          titleProp: 'product_info.title',
          codeProp: 'product_info.oem_code',
          numProp: 'num',
          refreshTable: true,
          closeDrawer: true,
        },
        // 更换产品(订单家具)
        switchFurnitureProduct: {
          listApi: 'product',
          addApi: 'sale-order-detail',
          addParamsKey: 'id',
          module: '',
          searchKey: '',
          productKey: 'id',
          searchId: '',
          addStatusKey: '',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
          refreshTable: true,
          closeDrawer: true,
        },
        // 模具组添加关联产品
        equipmentGroupProduct: {
          listApi: 'product',
          addApi: 'equipment-group-product',
          addParamsKey: 'equipment_group_id',
          module: '',
          searchKey: '__equipment_group_id',
          productKey: 'id',
          searchId: props.info?.id,
          addStatusKey: '__equipment_group_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
        },
        // 模具组详情
        equipmentGroupDetail: {
          listApi: 'equipment',
          addApi: 'equipment-group-detail',
          addParamsKey: 'equipment_group_id',
          module: '',
          searchKey: '__equipment_group_id',
          moduleKey: 'equipment_id',
          productKey: 'id',
          searchId: props.info?.id,
          addStatusKey: '__equipment_group_id',
          imgProp: 'image',
          titleProp: 'title',
          codeProp: 'oem_code',
          numProp: 'stock',
          refreshTable: true,
          tableColumns: [
            { label: '名称', prop: 'title', minWidth: 150 },
            { label: '编码', prop: 'sn', width: 100 },
            { label: '类型', prop: 'equipment_type_info.title', width: 80 },
            { label: '负责人', prop: 'mainer.name', width: 110 },
            {
              label: '状态',
              prop: 'status',
              tagTypeObj: EQUIPMENT_STATUS_COLOR,
              valFun: (row) => {
                return EQUIPMENT_STATUS[row.status]
              },
              width: 110,
            },
            {
              width: 80,
              label: '操作',
              prop: 'operation',
              fixed: 'right',
              action: {
                list: [''],
              },
            },
          ],
        },
      }

      const moduleInfo = apiModuleObj[props.module]
      const MODULE = moduleInfo.listApi // 模块

      console.log('moduleInfo :>> AddProductDrawer', moduleInfo)
      // 家具行业
      const furnitureArr = [
        'furnitureOrder',
        'furnitureStockup',
        'switchFurnitureProduct',
      ]
      const isFurniture = furnitureArr.includes(props.module)
      // 表格数据
      const tableColumns = computed(() => {
        if (moduleInfo.tableColumns) {
          return moduleInfo.tableColumns
        }

        let defaultColumns = [
          {
            type: 'selection',
            width: 1,
            fixed: 'left',
            attrs: {
              'reserve-selection': true,
              selectable: (row) => {
                return (row.num || 9999) - (row?.delivery_plan_num || 0) > 0
              },
            },
          },
          {
            label: '封面图',
            prop: moduleInfo.imgProp || 'image',
            width: 100,
            isImg: true,
            slotPreName: 'imageTag',
          },
          {
            label: '名称',
            prop: moduleInfo.titleProp || 'title',
            minWidth: 100,
            sortable: 'custome',
            class: 'cp cb tdu',
            drawerConfig: {
              tabPaneName: 'productPanel',
              desListName: 'productDescription',
              attrs: {
                title: '产品详情',
                size: '62%',
                // withHeader: false,
                destroyOnClose: true,
              },
              api: {
                detail: `/${MODULE}/detail`,
                update: `/${MODULE}/edit`,
              },
            },
            // publicDrawerConfig: {
            //   openName: 'productDetail',
            //   api: {
            //     valueKey: moduleInfo.productKey || 'id',
            //   },
            // },
          },
          {
            label: '编码',
            prop: 'code',
            sortable: 'custome',
            tips: '产品编码/OEM编码',
          },
          {
            label: '库存',
            tips: '库存 - 冻结库存 = 可用库存）(单位)',
            width: 130,
            prop: isFurniture ? 'furnitureNum' : moduleInfo.numProp || 'num',
            valFun: (row) => {
              const stockNum =
                row[moduleInfo.numProp || 'num'] ||
                row[moduleInfo.numProp || 'stock']
              const unitStr = ` (${row.unit || '-'})`
              if (moduleInfo.numProp === 'deliveryPlanAdd') {
                return `${row.num - row.delivery_plan_num}${unitStr}`
              } else if (isFurniture) {
                return `${stockNum}${unitStr}`
              } else {
                const freeze_num = row?.dynamic_info?.freeze_num || 0
                return `${stockNum} - ${freeze_num} = ${
                  stockNum - freeze_num
                }${unitStr}`
              }
            },
          },
          // { label: '供应商', prop: 'supplier_info.title' },
          {
            label: '默认仓库',
            prop: 'default_repository_id',
            valFun: (row) => {
              return (
                row?.default_repository_info?.title ||
                row?.product_info?.default_repository_info?.title ||
                '-'
              )
            },
          },
          {
            // hide: !props.addNow,
            width: 80,
            label: '操作',
            prop: 'operation',
            fixed: 'right',
            action: {
              // 添加家具产品
              furnitureProductCopy: {
                type: 'editBox',
                editBoxName: 'copyFurnitureProduct',
              },
              list: ['furnitureProductCopy'],
            },
          },
        ]

        if (isFurniture) {
          defaultColumns[3] = {
            label: '尺寸/规格',
            width: 110,
            prop: 'sku',
          }
        }
        if (props.module === 'furnitureStockup') {
          defaultColumns[5] = {
            label: '供应商',
            prop: 'order_info.supplier_info.title',
          }
          defaultColumns[6] = {
            label: '所述仓库',
            prop: 'staus',
            editType: 'tag',
            tagTypeObj: furnitureRepositoryStatusColor,
            valFun: (row) => {
              return furnitureRepositoryStatus[row.status]
            },
          }
        }
        return defaultColumns
      })
      console.log('tableColumns :>> ', tableColumns)
      // // 当用户手动勾选数据行的 Checkbox 时触发的事件
      // const handleTableSelect = (selection, row) => {
      //   console.log('selection :>> ', selection)
      //   console.log('row :>> handleTableSelect', row)
      //   state.selectList = selection
      // }

      const cellStyleFun = ({ columnIndex }) => {
        // console.log('columnIndex :>> ', columnIndex)
        if (columnIndex === 0) {
          return {
            // display: 'none',
            width: '1px',
            overflow: 'hidden',
          }
        }
      }

      const handleListOtherParams = () => {
        let result = {}
        // 特殊处理只能取成品
        if (
          moduleInfo.listApi === 'product' &&
          !moduleInfo.searchKey?.includes('nature')
        ) {
          result.nature = 0
        }
        // 出入库记录(成品|半成品)
        if (props.module === 'repositoryRecord') {
          result.nature = ''
          // result['nature|<='] = 3
        }
        if (props?.info?.otherParamsFun) {
          const res = props?.info?.otherParamsFun?.(props.info) || {}
          result = { ...result, ...res }
        }
        if (moduleInfo.listApi === 'product') {
          result.status = 1
        }
        return result
      }

      const handleGetProductListSort = () => {
        // 当前table的菜单ID
        const currentPageMenuId = userMenusAuthObj.value?.[PRODUCT_PATH]?.id
        const tableFields = userFieldList.value[currentPageMenuId]
        const result = {}
        if (tableFields && F.isObject(tableFields)) {
          for (const [key, value] of Object.entries(tableFields)) {
            if (value.is_default_sort) {
              result.sort = key
              result.order = value.sort
            }
          }
        }
        return result
      }

      const productSort = handleGetProductListSort()
      console.log('productSort :>> ', productSort)
      let tableConfig = reactive({
        searchBtnInline: true,
        headerActions: {
          list: [''],
        },
        tableColumn: tableColumns,
        tableAttr: {
          'row-key': 'id',
          headerCellStyle: cellStyleFun,
          cellStyle: cellStyleFun,
        },
        tableEvents: {
          // select: handleTableSelect,
        },

        tableListApi: `/${MODULE}/index`,
        tableUpdateApi: `/${MODULE}/edit`,
        tableDeleteApi: `/${MODULE}/delete`,
        tableExportApi: `/${MODULE}/export`,
        tableListParams: {
          sort: productSort.sort || 'id',
          order: productSort.order || 'desc',
          sorts: moduleInfo.sorts || {},
          [moduleInfo.searchKey]: moduleInfo.searchId,
          [moduleInfo.addStatusKey]: props.info?.id,
          ...handleListOtherParams(),
        },
        tableDeleteParams: {},
        tableButtonNum: 3,
        useField: false,
        tableSearch: productAddSearchConfig(props.module, props.info),
        tableDrawer: {
          api: {
            detail: `/${MODULE}/detail`,
          },
          productPanel: {
            tabsAttrs: {
              type: 'border-card',
            },
            activeName: '',
            tabPaneList: [
              {
                label: '基本信息',
                name: 'baseInfo',
                lazy: true,
                component: markRaw(ProductDetailBase),
                params: {
                  module: MODULE,
                },
              },
              {
                label: '产品概览',
                name: 'baseInfo2',
                lazy: true,
                component: markRaw(ProductDetailBase),
                params: {
                  module: MODULE,
                  type: 'common',
                },
              },
              {
                label: '配套产品',
                name: 'ProductPackageList',
                lazy: true,
                component: markRaw(ProductPackageList),
                params: {
                  module: MODULE,
                  from: 'product',
                  searchKey: 'product_id',
                  valKey: 'id',
                },
              },
              {
                label: '库存',
                name: 'stockInfo',
                lazy: true,
                component: markRaw(ProductRepository),
                params: {
                  module: MODULE,
                  from: 'product',
                  searchKey: 'product_id',
                  valKey: 'id',
                },
              },
              {
                label: '供应商',
                name: 'supplierInfo',
                lazy: true,
                component: markRaw(ProductSupplier),
                params: {
                  module: MODULE,
                  from: MODULE,
                  valKey: 'id',
                  searchKey: 'product_id',
                },
              },
              {
                label: '跟进',
                name: 'FollowUpList',
                lazy: true,
                component: markRaw(FollowUpList),
                params: {
                  module: MODULE,
                },
              },
              {
                label: '附件',
                name: 'annexInfo',
                lazy: true,
                component: markRaw(FileList),
                params: {
                  module: MODULE,
                },
              },
              {
                label: '客诉信息',
                name: 'FeedbackList',
                lazy: true,
                component: markRaw(FeedbackList),
                params: {
                  module: MODULE,
                  from: 'product',
                  searchKey: 'product_id',
                  valKey: 'id',
                },
              },
              {
                label: '渠道管理',
                name: 'channelList',
                lazy: true,
                component: markRaw(ProductChannelManage),
                params: {
                  module: MODULE,
                  from: 'product',
                  searchKey: 'product_id',
                  valKey: 'id',
                },
              },
              {
                label: '箱规管理',
                name: 'ProductCartonList',
                lazy: true,
                component: markRaw(ProductCartonList),
                params: {
                  module: MODULE,
                  from: 'product',
                  searchKey: 'product_id',
                  valKey: 'id',
                },
              },
            ],
          },
          productDescription: isAutoParts.value
            ? productDesTable('', 'header')
            : null,
        },
        copyFurnitureProduct: productFurnitureCopyAddDialog(
          MODULE_PRODUCT,
          'furniture'
        ), // 家具产品
      })

      const handleTableAction = (action, row) => {
        console.log('action :>> ', action)
        console.log('row :>> ', row)
      }

      const handleHeaderAction = (action) => {
        console.log('action :>> ', action)
      }

      const handleSelectionChange = (val) => {
        console.log('val handleSelectionChange', val)
        const newValArr = val.filter((item) => {
          return !selectListIds.value.includes(item.id)
        })
        const newVal = [...addProductDrawerSelect.value, ...newValArr]
        state.selectList = newVal
        store.commit('sys/setAddProductDrawerSelect', newVal)
      }

      const handleAddProductReq = (productId, supplierId = '') => {
        console.log('supplierId :>> ', supplierId)
        const params = {
          [moduleInfo.addParamsKey]: props.info?.id,
          [moduleInfo?.moduleKey || 'product_id']: productId,
        }
        if (supplierId) {
          params.supplier_id = supplierId
        }
        let url = `${moduleInfo.addApi}/add`
        // 特殊处理
        const specialUrlObj = {
          thirdShop: `${moduleInfo.addApi}/edit`,
          furnitureStockup: `${moduleInfo.addApi}/stock`,
          switchFurnitureProduct: `${moduleInfo.addApi}/switch`,
        }
        if (specialUrlObj[props.module]) {
          url = specialUrlObj[props.module]
        }
        request({
          url,
          method: 'post',
          data: params,
        }).then(() => {
          refTable.value.initSearch()
          // 刷新
          if (moduleInfo.refreshTable) {
            if (moduleInfo.closeDrawer) {
              handleCloseDrawer()
            }
            store.commit('sys/setRefreshTable', true) // 刷新
          }
          emit('add-product')
        })
      }

      const handleAddProduct = (item) => {
        if (!props.addNow) {
          if (deleteProductSelectInfo?.value?.id) {
            store.commit('sys/setDeleteProductSelectInfo', ref(null))
          }
          if (!selectListIds.value?.includes(item.id)) {
            const newVal = [...addProductDrawerSelect.value, ...[item]]
            state.selectList = newVal
            store.commit('sys/setAddProductDrawerSelect', newVal)
          }
          // refTable.value?.jwtTableRef?.tableRef?.toggleRowSelection(item, true)
          // item.added = !item.added
          return
        }
        if (!item.added) {
          const productId = item[moduleInfo.productKey]
          handleAddProductReq(productId, item?.supplier_id)
        }
        console.log('object :>> ', item)
      }

      const handleAddBatch = () => {
        if (!props.addNow) {
          emit('batch-add', state.selectList)
          return
        }
        const productIds = []
        state.selectList.forEach((item) => {
          if (!item.added) {
            productIds.push(item[moduleInfo.productKey])
          }
        })
        if (productIds.length) {
          handleAddProductReq(productIds)
        }
      }

      const showDrawer = () => {
        state.visible = true
      }

      const handleCloseDrawer = () => {
        state.visible = false
      }

      const handleClose = () => {
        state.init = true
        emit('close')
      }

      const inputFocus = () => {
        console.log('hideValRef.value :>> ', hideValRef.value)
        hideValRef?.value?.focus()
        setTimeout(() => {
          hideValRef?.value?.blur()
        }, 100)
      }

      /**
       * 按钮
       * @param {*} scope
       */
      const handleBtnDisable = (scope) => {
        return (
          scope.added ||
          selectListIds.value.includes(scope.id) ||
          (module === 'deliveryPlanAdd' &&
            scope.num - scope.delivery_plan_num === 0)
        )
      }

      /**
       * 按钮文字
       * @param {*} scope
       */
      const handleBtnText = (scope) => {
        if (props.module === 'thirdShop') {
          return t('绑定')
        } else if (
          props.module === 'furnitureStockup' ||
          props.module === 'switchFurnitureProduct'
        ) {
          return t('选择')
        }
        return scope.added || selectListIds.value.includes(scope.id)
          ? t('已添加')
          : module === 'deliveryPlanAdd' &&
            scope.num - scope.delivery_plan_num === 0
          ? t('库存不足')
          : t('添加')
      }

      onMounted(() => {
        setTimeout(() => {
          inputFocus()
        }, 500)
      })

      return {
        hideValRef,
        refTable,
        Close,
        tableConfig,
        selectListIds,
        ...toRefs(state),
        handleAddProduct,
        handleTableAction,
        handleHeaderAction,
        handleAddBatch,
        showDrawer,
        handleCloseDrawer,
        handleClose,
        handleSelectionChange,
        t,
        handleBtnDisable,
        handleBtnText,
      }
    },
  })
</script>

<style lang="scss">
  .add-product-close-btn {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 10;
    font-size: 20px !important;
    font-weight: lighter;
  }
  .add-product-modal-class {
    // display: none;
    top: 0;
    left: 0;
    bottom: 0;
    width: 38%;
  }
</style>
