<!--
 * @Author: WGL
 * @Date: 2023-12-07 10:37:10
 * @LastEditors: WGL
 * @LastEditTime: 2024-11-27 10:09:07
 * @Description: 
-->
<template>
  <el-input-number
    class="input-number-tips"
    :class="inputTipsClass"
    :controls="true"
    controls-position="right"
    :max="999999"
    :min="0"
    :model-value="numD"
    :placeholder="placeholder"
    :step="1"
    @blur="handleInputBlur"
    @change="handleInputChange"
  />
  <el-tooltip v-if="showTips" :content="tipsText" effect="dark" placement="top">
    <jwt-icon class="cy ml5 fs18 cp" icon="information-fill" />
  </el-tooltip>
</template>

<script setup>
  import { defineProps, defineEmits, computed } from 'vue'
  import { useStore } from 'vuex'

  const store = useStore()
  const props = defineProps({
    numD: { type: [String, Number], default: null }, // 值,
    numDanger: { type: Boolean, default: false }, // 值,
    row: { type: Object, default: () => {} }, // 行数据
    type: { type: String, default: 'sale' },
    info: { type: Object, default: () => {} }, // 详情信息
  })
  const emit = defineEmits([
    'update:numD',
    'update:numDanger',
    'change',
    'blur',
  ])

  // 最小起订量
  const minNum = computed(() => {
    const productInfo = props.row?.product_info || props.row || {}
    return productInfo.moq || -Infinity
  })

  function handleCheckPice(val) {
    if (props.type === 'sale') {
      return +val < +minNum.value
    }
    return false
  }
  // 显示提示
  const showTips = computed(() => {
    return handleCheckPice(props.numD)
  })
  // 销售模式
  const saleQuoteMode = computed(() => store.getters['sys/saleQuoteMode'])
  const purchaseQuoteMode = computed(
    () => store.getters['sys/purchaseQuoteMode']
  )

  const tipsText = computed(() => {
    if (props.type === 'sale') {
      return saleQuoteMode.value
        ? '产品数量低于最低起售数量不可提交,请调整!'
        : `产品数量低于最低起售数量 ${minNum.value} !`
    }
    return ''
  })

  const inputTipsClass = computed(() => {
    let result = ''
    if (showTips.value) {
      if (props.type === 'sale') {
        result = saleQuoteMode.value
          ? 'input-danger-tips'
          : 'input-warning-tips'
      }
    }
    return result
  })

  function handleInputBlur(e) {
    const value = e.target.value
    const belowminNum = handleCheckPice(value)
    if (props.type === 'sale') {
      emit('blur', +value, belowminNum && saleQuoteMode.value)
    }
  }

  function handleInputChange(value) {
    emit('update:numD', +value)
    const belowminNum = handleCheckPice(value)
    if (
      (props.type === 'sale' && saleQuoteMode.value) ||
      (props.type === 'purchase' && purchaseQuoteMode.value)
    ) {
      emit('update:numDanger', belowminNum)
    }
  }
</script>
<script>
  export default {
    name: 'BaseNumInput',
  }
</script>
<style lang="scss">
  $warning-color: #ff9900;
  $primary-color: #1890ff;
  $history-color: #67c23a;
  $danger-color: #ff4949;
  .input-number-tips {
    .el-input-number__decrease,
    .el-input-number__increase {
      display: none;
    }
    .el-input__wrapper {
      padding-right: 15px !important;
      .el-input__inner {
        min-width: 70px;
      }
    }
    &.input-price-advise {
      .el-input__inner {
        color: $primary-color !important;
        font-size: 16px;
        font-weight: bold;
      }
    }
    &.input-price-history {
      .el-input__inner {
        color: $history-color !important;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .input-danger-tips {
    .el-input {
      .el-input__wrapper {
        box-shadow: 0 0 0 1px $danger-color inset;
        background-color: lighten($danger-color, 30%);
      }
    }
  }
  .input-warning-tips {
    .el-input-number__decrease,
    .el-input-number__increase {
      display: none;
    }
    .el-input {
      .el-input__wrapper {
        box-shadow: 0 0 0 1px $warning-color inset;
        background-color: lighten($warning-color, 30%);
      }
    }
  }
</style>
